/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it


//gatsby-background-image
// export const onClientEntry = () =>{
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (!(`IntersectionObserver` in window)) {
//       import(`intersection-observer`)
//       console.log(`# IntersectionObserver is polyfilled!`)
//     }
// }

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }
  }